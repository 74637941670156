import './styles/app.css';
import './styles/components/nav.css';
import './styles/components/hero.css';
import './styles/components/featureds.css';
import './styles/components/gallery.css';
import './styles/components/homeServicesAdvance.css';
import './styles/components/footer.css';
import './styles/components/login.css';
import './styles/components/register.css';
import './styles/components/staticPageSection/basic-000/basic-000.css';
/* USER PROFILE */
import './styles/components/userProfile/userProfile.css';
/* CATALOG PRODUCT */
import './styles/components/catalogProducts/basic-000/basic-000.css';
/* CONTACT */
import './styles/components/contact/contact.css';
import './styles/components/contact/contactData.css';
import './styles/components/contact/contactForm.css';
/* SHOP */
import './styles/components/shop/bannerShop.css';
import './styles/components/shop/forCategory.css';
import './styles/components/shop/product-data.css';
/*import './styles/components/shop/picturesGallery.css';*/
import './styles/components/shop/productDetail.css';
/* GALLERY PRODUCT */
import './styles/components/productSliderGallery/productSliderGallery.css';
import './styles/components/shop/productFinishSelector.css';

/* navbars */
import './styles/components/mobile_cart.css';

/* SERVICES */
import './styles/components/serviceHandmadeJewelry.css';


import './bootstrap.js';
/*
* Welcome to your app's main JavaScript file!
*
* This file will be included onto the page via the importmap() Twig function,
* which should already be in your base.html.twig.
*/
//import $ from 'jquery';
/*jquery(document).ready(function () {
    jquery('body').prepend('<h1>' + greet('jill') + '</h1>');
});*/
console.log('This log comes from assets/app.js - welcome to AssetMapper! 🎉');
